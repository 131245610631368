button {
  font-family: "Yantramanav", sans-serif;
}

body {
  margin: 0;
  font-family: "Yantramanav", sans-serif;
  font-weight: 400;
  font-style: normal;
  background: black;
}
